.social {
	ul {
		list-style-type: none;
	}

	li {
		display: inline;
		margin-right: 15px;
	}

	a {
		color: $grey;
		font-size: 20px;
	}
}

#cff {
	.cff-date {
		color: $grey;
		margin: 0;
	}
	.cff-item:last-child {
		margin-bottom: 10px;
	}
	.cff-item {
		padding: 5px 0;
		&:last-child {
			border-bottom: none;
		}
	}
	.cff-date-dot {
		display: none;
	}
	.cff-text {
		a:after {
			content: "...";
		}
	}
	.cff-expand {
		display: none !important;
	}
	a {
		color: $color__text-main;
	}
}

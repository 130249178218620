.widget-area {
  .widget {
    margin: 0 0 40px;
    padding: 30px;

    ul {
      margin: 0;
      padding: 0;
    }
  }
}

.widget-neutre {
  background-color: $grey-light;
}

.widget-fonce {
  background-color: $grey-dark;
}

.widget-couleur {
  background-color: $primary;
}


.widget-title,
.widgettitle {
  color: #626262;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  font-family: "DINPro";
  margin: 0 0 20px;
}

/*
/* WIDGETS NEWSLETTER 
*/
.widget-newsletter {
  color: #626262;

  .description {
    padding: 20px;
    text-align: center;
  }

  .form-fields {
    position: relative;
  }

  input[type="email"] {
    background-color: #fff;
    border-radius: 25px;
    border: none;
    height: 55px;
    width: 100%;
    padding-left: 15px;
  }

  input[type="submit"] {
    position: absolute !important;
    top: 3px;
    right: 5px;
    color: #626262;
    background-color: $primary;
    border-radius: 30px;
    padding: 12px;
  }

}

.widget-newsletter-light {

  display: flex;
  justify-content: center;
  align-items: center;
  // background: $secondary;

  div {
    width: 100%;
  }

  form {
    width: 100%;
    background: none;
    margin: auto;
  }

  @include respond-to('medium') {
    height: 675px;

    form {
      width: 50%;
    }
  }

}


/*
/* WIDGETS AGENDA 
*/
.widget-agenda-jour {
  color: #616161;
  padding: 30px;

  .journee {
    text-align: center;
    line-height: 1;
    font-family: 'EB Garamond', serif;
    margin-bottom: 40px;
  }

  .jour {
    font-size: 100px;
    display: inline-block;
    border-bottom: 2px solid;
    margin-bottom: 5px;
  }

  .mois {
    font-size: 24px;
    text-transform: uppercase;
  }

  .annee {
    font-size: 42px;
  }

  ul {
    height: 180px;
    overflow-y: auto;
  }

  li {
    display: flex;
    width: 100%;
    font-weight: bold;
    padding: 5px 0;
  }

  li+li {
    // border-top: 2px solid #ffd764;
    border-top: 2px solid lighten($primary, 5%);
  }

  .heure {
    font-size: 24px;
    width: 30%;
  }

  .libelle {
    line-height: 1.3;
    padding-left: 10px;
    width: 70%;
    padding-top: 15px;
  }
}


/*
/* WIDGETS ACTUALITE HOME 
*/

.widget-hp-actu {
  color: #fff;
  font: normal 22px 'DINPro-condmedium';
  text-align: center;
  text-transform: uppercase;

  p {
    margin: 0;
  }

  a {
    display: block;
    width: 100%;
    color: #fff;
    min-height: 275px;
    padding-top: 30px;

    &:hover {
      display: block;
      width: 100%;
      color: #fff;
      min-height: 275px;
      padding-top: 30px;
    }
  }

  img {
    margin: 30px auto;
  }
}

.widget-hp-jour {
  a {
    color: #626262;

    &:hover {
      color: #626262;
    }
  }

  .jour {
    font-size: 100px;
    display: inline-block;
    border-bottom: 2px solid;
    margin-bottom: 5px;
  }

  .mois {
    font-size: 24px;
    text-transform: uppercase;
  }

  .annee {
    font-size: 42px;
  }
}

.widget-hp-clair {
  a {
    color: #fff;
  }
}



.widget-dernieres-actualites {
  margin-top: 40px;

  h2 {
    text-align: left;
    color: #e8a702;
    text-transform: initial;
  }

  ul {
    display: flex;
    // align-items: stretch;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  li {
    width: 380px;
    list-style: none;
    margin-bottom: 30px;
  }

  a {
    display: block;
  }

  .article-titre {
    color: $grey-dark;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    line-height: 24px;
    margin-top: 15px;
    min-height: 50px;
  }

  .article-image {
    display: block;
  }
}

.articles-tous {
  clear: both;
  margin-bottom: 25px;
}


.fil-info.fil-info-hp {
  padding: 0 15px;
}

.fil-info {
  h5 {
    position: relative;
    display: inline-block;
    font: normal 18px 'DINPro-condbold';
    text-transform: uppercase;
    color: #626162;
    padding: 6px 4px;
    background-color: $primary;
    margin-top: 0;

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 40%;
      display: inline-block;
      height: 0;
      width: 0;
      border-top: 10px solid darken($primary, 5%);
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
}

.liste-articles-recents {
  margin: 0;
  padding: 0;
  margin-top: 10px;

  li {
    list-style: none;
    padding: 10px 0;
    display: flex;
  }

  li+li {
    border-top: 3px solid #eeeeee;
  }

  .article-image {
    margin-right: 5px;

    a {
      display: inline-block;
      width: 70px;
      height: 70px;
      margin-right: 10px;
    }
  }

  .article-infos {
    font-size: 14px;
  }

  .article-titre {
    display: block;
    color: #434343;
    font-weight: bold;
    line-height: 1.1;

  }

  .article-categorie {
    text-transform: uppercase;
    font-weight: bold;
    color: darken($primary, 5%);
  }

  .article-date {
    display: inline-block;
    color: #949494;

    &:before {
      content: "|";
      margin: 0 5px;
    }
  }
}


// Widgets RSS
.widget_rss {

  .widgettitle a {
    color: #626262;

    .rsswidget:first-child {
      display: none;
    }
  }

  margin-bottom: 40px;

  li+li {
    margin-top: 20px;
  }
}

.rsswidget {
  display: block;
  font-weight: bold;
}

.rss-date {
  display: block;
  color: #666;
  font-size: 0.8rem;
}

//  Widget caregories
.widget_categories {
  padding: 20px;
  background: $grey-light;

  a {
    color: #666;

    @extend %fa-icon;
    @extend .fas;

    &:before {
      content: fa-content($fa-var-tags);
      padding-right: 5px;
    }

    // &:hover {
    //   text-decoration: underline;
    // }
  }
}


// Widget Events
.tribe-events-widget .tribe-events-widget-events-list__view-more-link:visited {
  color: $primary;
}

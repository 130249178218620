.sitemap {
  a {
    color: $color__text-main;
  }

  li {
      list-style: none;
  }

  .menu {
      display: flex;
      flex-wrap: wrap;

      > li {
        flex: 1 1 35%;
        padding: 20px;
        background: $grey-light;
        margin: 20px;

        & > a {
            text-transform: uppercase;
            font-size: 2.5rem;
            text-align: center;
        }
        &>ul {
            margin: 10px 0;
            &>li {
              margin: 20px 0;
            }
            ul ul{
                margin-bottom: 20px;
            }
        }
      }
  }
}

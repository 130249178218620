.personne-card {
  @include respond-to('narrow') {
    display: flex;
    // align-items: flex-start;
  }

  color: #fff;
  max-width: 500px;
  padding: 15px;
  background: $grey-dark;
  margin: 10px 0;

  .nom {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  strong {
    margin-bottom: 5px;
  }

  i {
    color: lighten($secondary, 20%);
  }

  figure {
    margin-top: 0;
    margin-right: 15px;
  }
}

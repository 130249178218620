.archive-header {
}

.ruban {
	text-align: center;
	text-transform: uppercase;
	background-color: $primary;

	h1 {
		font-family: "DINPro-cond";
		color: #fff;
		font-size: 50px;
		margin: 0;
		background: url("../img/picto-dossiers.png") 50% 50% no-repeat;
	}

	.big {
		font-weight: bold;
		font-size: 50px;

		@include respond-to("medium") {
			font-size: 90px;
		}
		@include respond-to("wide") {
			font-size: 110px;
		}
	}
}

.archive-dossier {
	.archive-body {
		article {
			position: relative;
			a {
				display: block;
			}
		}

		.title {
			position: absolute;
			top: 55px;
			left: 0;
			color: #fff;
			font-size: 50px;
			font-family: "DINPro-cond";
			line-height: 41.5px;
			text-align: center;
			text-shadow: 1px 1.7px 3px #00000091;
			padding: 0 20px;
		}
		.excerpt {
			font-size: 19px;
			font-weight: bold;
			line-height: 1.26;
		}

		.read-more {
			position: absolute;
			top: 200px;
			left: 30px;
			font-size: 12px;
			font-weight: bold;
			color: #fff;
			background-color: $primary;
			padding: 5px 20px;
			border-radius: 20px;
		}
	}
}

.archive-footer {
	font-size: 14px;
	margin: 20px 200px;

	.page-numbers.current {
		@extend %btn;
		@extend %btn-primary;
		border-radius: 0;
		color: #fff !important;
		font-family: "DINPro-bold";
	}

	a {
		color: $color__text-main;
		text-decoration: none;
		padding: 0 5px;
		margin: 0 5px;
	}
}

@media all and (min-width: 768px) {
	.archive-dossier .archive-body {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 80px 200px 0;

		article {
			width: 380px;
			margin: 10px 0;
			//   margin: 20px;
		}
	}
}

// Archive agenda

.archive-agenda {
	.archive-body {
		border-left: 8px solid $primary;
		padding-left: 20px;
		@include respond-to("medium") {
			margin: 80px 200px 0;
		}
	}
}

@media all and (min-width: 768px) {
	.archive-body {
		display: block;

		// .post {
		//   width: 380px;
		//   margin: 10px 0;
		//   //   margin: 20px;
		// }
	}
}

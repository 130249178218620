form {
	// background-color: $grey-light;
	// padding: 25px;

	.label {
		color: #666;
		font-size: 16px;
		padding-bottom: 15px;
	}

	.required {
		color: $color__alert;
	}

	input[type="text"],
	input[type="email"],
	input[type="url"],
	input[type="password"],
	input[type="search"],
	input[type="number"],
	input[type="tel"],
	input[type="range"],
	input[type="date"],
	input[type="month"],
	input[type="week"],
	input[type="time"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="color"],
	textarea,
	select {
		color: $color__text-input;
		border: 1px solid $color__border-input;
		border-radius: 3px;
		padding: 3px;
		width: 100%;
	}

	input[type="submit"],
	button {
		border: none;
		@extend %btn;
		@extend %btn-primary;
	}

	// textarea {
	//     width: 100%;
	// }

	p {
		margin-bottom: 30px;

		:last-child {
			margin-bottom: 0;
		}
	}
}

.form-rgpd {
	background-color: #efefef;
	margin-top: 20px;
	padding: 5px 5px 0px 5px;
	font-size: 0.88em !important;

	p {
		font-size: 0.88em !important;
	}

	a {
		color: #e8a702;
	}
}

.form-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
}

// FORM 7

.wpcf7-form-control-wrap {
	width: 300px;
	margin: 0 auto;
}

span.wpcf7-list-item {
	display: block;
}

.wpcf7-form-control-wrap .message {
	margin-bottom: 30px;
}

/*
 * FORM CONTACT
 */

.form-intro {
	background-color: #efefef;
	padding: 25px;
}

.form-intro p:first-child {
	font-size: 24px;
	line-height: 1.1;
	padding: 0 50px;
}

.form-intro-small p:first-child {
	font-size: 20px;
}

@import "form_don";

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
	display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
@import "captions";

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
@import "galleries";

// Flickr Albulm Gallery
.blueimp-gallery {
	> .indicator > li {
		height: 70px;
		width: 70px;

		// retire l'incrÃ©mentation ol li du theme
		&:before {
			content: none;
		}

		// retire l'arrondit de l'image miniature
		&:after {
			border-radius: unset;
		}
	}
}

// player youtube embed
.youtube_player {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
}
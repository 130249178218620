.cta {
	position: fixed;
	top: 250px;
	right: 0;
	
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	img {
		width: 75px;
		height: 75px;
		@include respond-to("wide") {
			width: 145px;
			height: 145px;
		}
	}

	.bigger {
		img {
			width: 85px;
			height: 85px;

			@include respond-to("wide") {
			  width: 165px;
			  height: 165px;
			}
		}
	}
}

.cta-cellule {
	margin-bottom: 20px;
	text-align: center;
	font-size: 12px;
	color: #fff !important;
	background-color: #d84927;
	padding: 3px;
	width: 75px;
	height: 75px;
	span {
		display: none;
	}
	p {
		margin: 0;
	}

	@include respond-to("wide") {
		width: 147px;
		height: 145px;
		padding: 10px;
		span {
			display: inline-block;
			color: #fff !important;
		}
		.btn {
			background-color: #fff;
			color: $primary !important;
			text-transform: uppercase;
			font-size: 9px;
			margin-top: 10px;
		}
		p {
			display: block;
			margin: 5px 0;
		}
	}
}

.cta-close {
  position: absolute;
  top: -15px;
  left: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open sans';
  font-size: 18px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  border: none;
  background-color: red;
  box-shadow: 1px 1px 1px rgba(#000, .35);

  &:hover {
    cursor: pointer;
  }
}
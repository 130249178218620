.site-footer {

  .newsletter {
    background: $primary;
    color: #fff;
    padding: 25px;

    @include respond-to('medium') {
      padding: 50px;
    }

    .widgettitle {
      color: #fff;
    }

    @include respond-to('medium') {
      .widget {
        width: 35vw;
        margin: 0 auto;
      }
    }

    input[type="submit"] {
      color: #fff !important;
    }
  }


  ul {
    list-style: none;
    // margin: 0;
    padding: 0;
  }
}

.site-footer-top {
  color: #6f6f6f;
  padding: 75px 57px 24px;
  background-color: $grey-light;

  a {
    display: inline-block;
  }

  .col {

    h3 {
      text-transform: uppercase;
      color: #fff;
    }

    li {
      height: 32px;
      text-transform: uppercase;
      border-bottom: 1px solid $grey;
    }

    a {
      font-size: 12.5px;
      color: #6f6f6f;
      text-decoration: none;

      // &:hover {
      //     color: #fff;
      // }

    }

    &.contact {
      li {
        height: auto;
        text-transform: none;
        border-bottom: none;
      }

      li+li {
        margin-top: 20px;
      }

      a {
        font-size: 16px;
      }

      i {
        color: $primary;
        margin-right: 6px;
      }
    }
  }

  .menu-entete {
    font-family: 'DINPro-cond';
    font-size: 19.5px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 25px;
  }

}



.site-footer-bottom {
  // display: flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  padding: 0 40px;
  font-size: 12px;
  background-color: $grey-dark;

  div {
    padding: 10px 0;
  }

  .social a {
    color: #fff;
  }
}



@media all and (min-width: 768px) {

  .site-footer-top {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .col {
      flex: 1;
      margin: 0 10px;
    }

    .liens {
      width: 100%;
      text-align: center;
      margin-top: 55px;

      a {
        font-size: 14px;
        color: #6f6f6f;
        text-decoration: none;
        margin-right: 10px;
      }
    }
  }

  .site-footer-bottom {
    display: flex;
    align-items: center;

    div {
      margin: 0;
      flex: 1;
    }

    .social {
      text-align: right;
      order: 1;
    }
  }

}

@media all and (max-width: 768px) {

  .site-footer-top {
    padding: 75px 20px 30px;

    .logo {
      text-align: center;
    }

    img.logo {
      margin-bottom: 0;
    }

    .col {
      +.col {
        margin-top: 50px;
      }

      li {
        height: 40px;
        line-height: 40px;
      }
    }
  }

  .site-footer-bottom {
    text-align: center;
  }
}

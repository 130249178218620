.post-type-archive-ep {

  .archive-body {

    margin-top: 80px;

    @include respond-to('medium') {
      margin: 80px 200px 0;
    }

    a {
      color: #000;
      font: normal 30px "DINPro-cond";
      text-decoration: none;
    }
  }
}

.single-ep {

  .main-area {
    margin-bottom: 0;
  }

  .mise-en-avant {
    p:last-child {
      margin-bottom: 0;
    }

    margin-bottom: 2rem;
  }

  .ensemble {
    display: flex;
    flex-direction: column;

    @include respond-to("medium") {
      flex-direction: row;
      align-items: flex-start;
    }

    h2 {
      font-size: 2rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .cooperateurs div {
    margin-bottom: 1rem;
  }

  .composition {
    margin-bottom: 2rem;

    h2:first-child() {
      margin-top: 0;
    }

    @include respond-to("medium") {
      flex: 2;
    }

    @include respond-to('tablet-only') {
      order: 2;
    }
  }

  ///////////////////////////////////////////

  .information {
    h3 {
      text-transform: uppercase;
      font-size: 1.5rem;
    }

    .horaires {
      ul {
        margin-top: 0;
      }

      p {
        margin: 0 0 10px 0;
        strong {
          text-transform: uppercase;
        }
      }
    }  

    @include respond-to("medium") {
      flex: 1;
      margin-left: 20px;
    }

    @include respond-to('tablet-only') {
      order: 1;
    }
  }

  .bloc {
    padding: 15px;
    // word-break: break-all;
    background: lighten($grey-light, 5%);
    margin-bottom: 2rem;

    h2 {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .fichiers {
      .wp-block-file a {
        background: #fff;

        &:hover {
          color: #fff;
          background: $primary;
        }
      }
    }
  }

  //////////////////////////

  .carte {
    .wpgmza_map {
      height: 400px !important;
    }
  }


}

.wpgmza_map {
	p {
		border: none;
	}

	.ol-info-window-plain {
		// height: 350px ;
		width: 600px;
		padding: 25px 15px 55px;
		font-size: 16px;
		// overflow-y: auto ;
		transform: translateX(-19%);
		margin-bottom: -14px;
		&:after {
			// content: "";
			// position: absolute;
			bottom: -50px;
			left: 15%;
			// width: 0;
			// height: 0;
			border: 30px solid transparent;
			border-top-color: #fff;
			// border-bottom-color: transparent;
			// border-bottom-style: solid;
			border-bottom-width: 30px;
		}
	}
}

.wpgmza_infowindow_description {
	img {
		float: left;
		width: 200px;
		height: auto;
		margin-right: 12px;
		margin-bottom: 12px;
	}
	p {
		margin: 10px 0;
	}
}

.wpgmza_infowindow_title {
	font-family: DINPro;
	font-size: 26px;
	font-weight: bold;
	text-transform: none;
	text-align: center;
	margin-bottom: 28px;
	margin-top: 0;
}
.wpgmza_infowindow_address {
	display: none;
}

// filtres
#wpgmza_filter_1 {
	text-align: center !important;
	margin-bottom: 0px;
	padding: 60px;
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	// align-content: center;
	// align-items: center;
	background-color: $grey-light;
	border: none;
	select {
		margin-left: 10px;
	}
}

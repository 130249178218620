/*
 * SLIDER ACCUEIL
 */
.section-slideshow {
	.slideshow {
		.slide {
			position: relative;
			width: 100%;
			overflow: hidden;
			// @include respond-to("medium") {
			// 	width: 768px;
			// }
			// @include respond-to("wide") {
			// 	width: 1024px;
			// }
			// @include respond-to("extra") {
			// 	width: 1200px;
			// }
			// display: block;
			// text-decoration: none;
		}
		img {
			width: 100%;
			@include respond-to("extra") {
				height: 535px;
			}
		}
		.ruban {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			width: 100%;
			padding-bottom: 5px;
			color: #fff;
			text-align: center;
			background-color: rgba($primary, 0.7);
			@include respond-to("medium") {
				padding-bottom: 25px;
			}
			&.hidden {
				background: none;
			}
		}
		.big {
			display: block;
			font-family: "DINPro-cond";
			font-size: 25px;
			text-transform: none;
			font-weight: normal;
			@include respond-to("narrow") {
				font-size: 30px;
			}
			// @include respond-to("medium") {
			// 	font-size: 75px;
			// }
			@include respond-to("medium") {
				font-size: 75px;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
		.btn {
			color: $primary !important;
			font-size: 12.5px;
			font-weight: bold;
			// text-transform: uppercase;
			padding: 8px 16px;
			background-color: #fff;
			@include respond-to("medium") {
				padding: 18px 30px;
			}
		}

		ul.slick-dots {
			width: 100%;
			list-style: none;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			margin-top: 10px;
			@include respond-to("medium") {
				margin-top: -5px;
				transform: translateX(-50%);
				left: 50%;
				position: absolute;
				margin-top: -40px;
			}
			button {
				@include hideText;
				background: none;
				border: 1px solid $primary;
				width: 20px;
				height: 20px;
				padding: 0;
				border-radius: 50%;
				margin-right: 15px;
				@include respond-to("medium") {
					border: 1px solid #fff;
					margin-right: 25px;
				}
			}

			li.slick-active {
				button {
					@include hideText;
					background-color: $primary;
					@include respond-to("medium") {
						background-color: #fff;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	//Carrousel dans les articles et pages
	.carrousel-post {
		max-width: 800px;
	}
}

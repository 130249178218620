html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
button,
input,
select,
optgroup,
textarea {
  color: $color__text-main;
  font-family: $font__main;
  @include font-size(1);
  line-height: $font__line-height-body;
}

// body {

//   @include respond-to(handhelds) {
//     font-size: 16px;
//     line-height: 20px;
//   }
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
}

h1,
.fake-title {
  color: $primary;
  font-family: "DINPro-cond";
  text-align: center;
  margin-top: 0;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 0.9;

  @include respond-to('medium') {
    font-size: 3.75rem;
    line-height: initial;
  }
}

h2 {
  color: rgb(90, 29, 5);
  font-size: 2rem;

  @include respond-to('medium') {
    font-size: 2.2rem;
  }
}

h3 {
  color: rgb(90, 29, 5);
  font-size: 1.5rem;

  @include respond-to('medium') {
    font-size: 2rem;
  }
}

h4 {
  text-transform: uppercase;
  font-size: 1.5rem;
}

p {
  margin-bottom: 1.5em;
}

.mise-en-avant {
  padding: 2rem;
  background: lighten($primary, 45%);
  border-left: 8px solid $primary;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

// pre {
// 	background: $color__background-pre;
// 	font-family: $font__pre;
// 	@include font-size(0.9375);
// 	line-height: $font__line-height-pre;
// 	margin-bottom: 1.6em;
// 	max-width: 100%;
// 	overflow: auto;
// 	padding: 1.6em;
// }

code,
kbd,
tt,
var {
  font-family: $font__code;
  @include font-size(0.9375);
}

abbr,
acronym {
  border-bottom: 1px dotted $color__border-abbr;
  cursor: help;
}

mark,
ins {
  background: $color__background-ins;
  text-decoration: none;
}

big {
  font-size: 125%;
}

// p {
//   font-size: $base-font-size;
//   // text-align: left;
//   margin-bottom: 1.5em;

//   @include respond-to(handhelds) {
//     font-size: 16px;
//   }
// }

.wp-pagenavi {
	text-align: center;
	margin-top: 50px;

	.current,
	a {
		font-size: 14px;
		font-weight: bold;
	}
	.current {
		@extend %btn;
		@extend %btn-primary;
		border-radius: 0;
		color: #fff !important;
	}

	a {
		color: $color__text-main;
		text-decoration: none;
		padding: 0 5px;
		margin: 0 10px;
	}
}

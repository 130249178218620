.acf-form {
  border: 1px solid #ccc;
  margin: 50px 0 0;
  padding: 20px 20px 40px;
}

.acf-field-repeater {
  .acf-actions {
    text-align: left;
  }

  .acf-button {
    @extend %btn;
    background: #aaa;
    margin: 10px auto;
  }
}

.acf-form-fields {
  /* Label des chamsp repeater */
  & > div > div > label {
    font-size: 1.5rem;
  }

  & > .acf-field {
    margin-top: 30px;
  }
//   & > .acf-label:first-child {
//     font-size: 1.5rem;
//   }

  .input.hasDatepicker {
    width: 150px;
  }
}

/* Titre */
.acf-field[data-name^="_"] {
  color: rgb(90, 29, 5);
  font-size: 1.5rem;

  @include respond-to("medium") {
    font-size: 2rem;
  }
}

.acf-form-submit {
  text-align: center;
  color: #fff !important;
}

/* Feedback */
.acf-form-submitted {
  display: inline-block;
  padding: 0 20px;
  border: 1px solid rgb(50, 121, 56);
  color: rgb(35, 85, 39);
  background: rgb(209, 240, 204);
  border-radius: 5px;

  margin-bottom: 20px;

  p {
    margin-bottom: 1em;
  }
}

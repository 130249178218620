a {
	color: $color__link;
	text-decoration: none;

	&:visited {
		color: $color__link-visited;
	}

	&:hover,
	&:focus,
	&:active {
		color: $color__link-hover;
	}

	&:focus {
		outline: thin dotted;
	}

	&:hover,
	&:active {
		outline: 0;
	}
}

.btn {
	@extend %btn;
}

.btn-primary {
	@extend %btn-primary;
}

.btn-secondary {
	@extend %btn-secondary;
}

.btn-danger {
	@extend %btn-danger;
}

ul,
ol {
    margin: 1.5em 0 1.5em 1em;
    padding-left: 0;
}

ul {
    list-style: disc;
}

// ol {
//     counter-reset: dio-counter;
// }

// ol li {
//     list-style: none;
//     counter-increment: dio-counter;
//     margin-bottom: 15px;
// }

// ol li:before {
//     content: counter(dio-counter);
//     color: #fff;
//     font: normal 18px 'DINPro';
//     padding: 3px 7px;
//     height: 20px;
//     vertical-align: middle;
//     background: $primary;
//     margin-right: 8px;
// }

li>ul,
li>ol {
    margin-bottom: 0;
    margin-left: 1.5em;
}

// li a:hover {
//     color: primary;
// }


dt {
    font-weight: bold;
}

dd {
    margin: 0 1.5em 1.5em;
}

.liste-horizontale {
    @extend .sous-nav;
}

.nicescroll-cursors {
    background-color: #93760d !important;
    width: 8px !important;
    border: none !important;
    // min-height: 100px !important;
}

.nicescroll-rails {
    background-color: rgba(#93760d, 0.3);
}
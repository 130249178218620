.content-header {
  .illustration {
    position: relative;
  }

  .big-title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    line-height: 1;
    font-family: "DINPro-cond";
    font-weight: bold;
    text-shadow: 1px 1.7px 3px #00000091;
    width: 100%;
    margin: 0;
    font-size: 30px;
    padding: 0 20px;

    @include respond-to("medium") {
    	font-size: 90px;
    }
    @include respond-to("wide") {
      font-size: 110px;
    }
  }
}

.single-post {
  .big-title {
    display: none;
  }
}

.main-area {
  margin-bottom: 40px;
}

// .actualites-body,
// .post-template-default .main-area,
// .page-template .main-area,
// .page-template-default .main-area,
// .dossier-template-default .main-area {
@include respond-to("medium") {
  article {

    &.page,
    &.post,
    &.dossier {
      margin: 0 200px;
    }

    &.page-variante {
      margin: 0 55px;
    }

    &.page-no-thumb {
      margin: 0;
    }
  }
}


.page-variante {
  @include respond-to("wide") {
    display: flex;
    margin: 0 60px;

    .entry-header {
      width: 25%;
    }

    .entry-content {
      width: 75%;
      margin-left: 2%;

      p,
      ul {
        border: none;
      }
    }
  }

  .entry-content {
    p {
      border: none;
      padding-left: 0;
    }
  }

  h1 {
    font-family: "DINPro-cond";
    font-size: 40px;
    font-style: normal;
    line-height: normal;
    // letter-spacing: normal;
    text-align: left;
    border-left: 8px solid $primary;
    padding-left: 20px;
    margin: 0;
    margin-bottom: 40px;
    
    @include respond-to('wide') {
      font-size: 62.5px;
    }
  }
}

// .entry-meta {
//   color: $grey;
//   font-size: 14px;

//   .author {
//     font-weight: bold;
//   }
// }

.entry-content {
  // p,
  // ul:not(.wp-block-gallery) {
  // border-left: 8px solid $primary;
  // padding-left: 15px;
  // &:last-child {
  // 		margin: 0;
  // 	}
  // }

  // ul:not(.wp-block-gallery),
  ul:not(.blocks-gallery-grid) {
    margin-left: 0;
    padding-left: 30px;
  }

  .has-medium-font-size {
    font-size: 26px;
  }
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
}

.has-border {
  border-left: 8px solid $primary;
  padding-left: 27px;
}

//
// SHORTCODES
//

// Horaires fÃªtes ensemble paroissial
.horaires-fetes {
  .ep {
    background: $grey-light;
    margin-bottom: 20px;

    &.vide {
      background: lighten($grey-light, 8%);
    }
  }

  .nom {
    padding: 10px 15px;
    font-size: 1.5rem;
    position: relative;

    &:after {
      content: '+';
      position: absolute;
      top: 8px;
      right: 20px;
      font-size: 26px;
    }

    &:hover {
      cursor: pointer;
      color: #fff;
      background: $grey;
    }
  }

  .horaires {
    padding: 10px 15px;

    h2,
    h3,
    h4 {
      font-size: 1.2rem;
    }
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.simple-banner-text {

    box-shadow: 0 0 30px rgba(0, 0, 0, .25);
    .tribe-events-widget.tribe-events-view--widget-countdown {
        margin-bottom: 0;
        color: #fff !important;
    }
    .tribe-events-widget-countdown {
        display: flex;
        flex-direction: column;
        align-items: center;
    } 
    .tribe-events-widget-countdown__event-title {
        margin-bottom: 0;
    }

    @include respond-to('medium') {
        .tribe-events-widget-countdown__event-title {
            margin-right: 35px;
            font-size: 1.3rem;
        }
        .tribe-events-widget-countdown {
            flex-direction: row;
            align-items: baseline;
            color: white;
            justify-content: center;
        }
    }
} 

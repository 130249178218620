/**
* Block Accordeon
*/
.uagb-faq-item {
  background: #efefef !important;
  padding: 10px;
}

.uagb-faq-question {
  // Retirer la bordure orange des paragraph Ã  l'intÃ©rieur
  p {
    border-left: none;
  }
}

.uagb-faq-content {
  p {
    border-left: none;
  }
}

/**
* Block table of content
*/
.wp-block-uagb-table-of-contents {
  .uagb-toc__wrap {
    background: #efefef;
  }

  .uagb-toc__list-wrap {
    margin-top: 15px;
  }

  ul {
    border: none !important;
    margin-top: 0;
  }
  li {
    list-style-type: square;
  }
}

.wp-block-file {
  margin: 1em 1em 1em 0;
  @include clearfix();
  display: block;

  a {
    display: inline-flex;
    color: $color__text-main;
    font-weight: bold;
    padding: 10px 15px;
    background: $grey-light;
    transition: 0.3s;

    &:hover {
      color: #fff;
      background: $primary;
    }

    &[href$=".pdf"] {
      &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-file-pdf);
        padding-right: 8px;
      }
    }
    &[href$=".doc"],
    &[href$=".ods"] {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-file-word);
      padding-right: 8px;
    }
    &[href$=".jpg"],
    &[href$=".jpeg"] {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-file-image);
      padding-right: 8px;
    }
  }
}

.wp-block-file__embed {
  margin-bottom: 20px;
}

.wp-block-button {
  background: transparent !important;
  a {
    color: #fff !important;
    @extend %btn;
  }

  &.btn-primary {
    a {
      color: #fff !important;
      @extend %btn-primary;
    }
  }
  &.btn-secondary {
    a {
      color: #fff !important;
      @extend %btn-secondary;
    }
  }
}


// colonne pleine largeur
@include respond-to('medium') {
  .full-width {
    width: 62.5vw;
    position: relative;
    left: 95%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  .with-padding {
    padding: 30px;
  }

  .no-gutters {
    .wp-block-column:not(:first-child) {
      margin-left: 0;
    }
  } 

}

// Ombre sur les FAQ items
.wp-block-uagb-faq-child.with-shadow .uagb-faq-item {
  background-color: initial !important;
  box-shadow: 0 0 25px rgba(0,0,0,.20);
  margin: 30px 0;
}
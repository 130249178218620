.header-top {
	display: flex;
	// flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	padding: 7px 25px 12px 25px;
	background-color: $grey-light;

	.logo {
		// margin-right: auto;

		img {
			height: 75px;
			width: 90px;
		}
	}

	.info-extra {
		display: flex;
		align-items: center;
		margin-left: 20px;
		max-width: 350px; 
	}

	.info-extra__logo {
		img {
			height: 75px;
			width: auto;
		}
	}

	.info-extra__text {
		margin-left: 10px;
	}

	div {
		display: flex;
	}

	/* .contact {
		padding-top: 6px;
		border-left: 1px solid #dfdfe0;

		a {
			color: #626262;
			font-size: 12.5px;
			text-decoration: none;
			text-transform: uppercase;
			padding: 0 20px;
		}
	} */

	.widget_search {
		width: 200px;
		margin-left: 20px;
	}

	.search-form {
		position: relative;
		padding: 0;
		width: 100%;
	}

	input[type="search"].search-field {
		font-size: 12.5px;
		border: none;
		padding-left: 12px;
		border-radius: 0;
		height: 38px;
	}

	input::placeholder {
		color: $grey-dark;
	}

	.search-submit {
		font-size: 16px;
		color: $primary !important;
		bottom: 0;
		// overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		width: 42px;
		border: none;
		background: none;
	}
}

.banner-info {
	font-size: 1rem;
	text-align: center;
	padding: 10px 5px;
	background: $primary;

	a {
		font-size: 1rem;
		color: #fff;
		font-weight: bold;

		&:hover {
			text-decoration: underline;
		}
	}
}

.header-bottom {
	display: flex;
	align-items: center;
	padding: 40px 0;

	.logo {
		display: none;
	}
}

.site-branding {
	flex: 3;
	text-align: center;

	a {
		display: block;
	}

	img {
		max-width: 70%;
	}
}

.menu-resp {
	display: none;
	position: fixed;
	z-index: 1000;
	padding: 10px;
	background: #fff;

	i {
		color: $primary;
		font-size: 26px;
	}
}

@include respond-to("tablet-only") {
	.menu-resp.open {
		display: block;
	}

	.header-top {
		position: fixed;
		height: 50px;
		padding: 5px;
		width: 100%;
		z-index: 500;
		display: flex;
		justify-content: space-between;
		box-shadow: 0 10px 10px rgba(0, 0, 0, .125);

		.logo img {
			height: 40px;
			width: auto;
			margin-left: 50px;
			// display: none;
		}

		.info-extra__logo {
			margin-left: 10px;

			img {
				height: 40px;
				// display: none;
			}
		}

		.info-extra__text {
			display: none;
		}

		.widget_search {
			display: none;
		}
	}

	.banner-info {
		margin-top: 50px;
		margin-bottom: 30px;
		width: 100%;
		z-index: 999;
	}

	.header-bottom {
		// margin-top: 110px;
		flex-direction: column;
		margin-bottom: 0;
		padding: 0;
	}

}
a.lien {
    color: $grey;
    font-size: 1rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #fff;
    padding: 10px 12px;
    transition: .3s;

    &+.lien {
        margin-left: 10px;
    }

    &:hover {
        color: #fff;
        background: $primary;
    }

    &.don {
        color: #fff;
        background: $primary;

        &:hover {
            color: $primary;
            background: #fff;
        }

        i {
            margin-right: 5px;
        }

        display: flex;
        align-items: center;
        width: auto;
        border-radius: 25px;
    }
}

@media only screen and (max-width: 420px) {

    .lien {

        &.dossier,
        &.carte {
            display: none;
        }

        &.don {
            // font-size: 14px;
            line-height: 1;
            padding: 5px 6px;
        }
    }
}
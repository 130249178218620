.main-navigation {
	// clear: both;
	// display: block;
	width: 100%;
	position: relative;
	// padding-top: 25px;

	ul {
		// list-style: none;
		margin: 0;
		padding-left: 0;

		&:first-child {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			> li {
				margin: 0 20px;
				> a {
					// display: block;
					font-family: "DINPro-cond";
					font-size: 33px;
					color: #3f2d23;
					text-transform: uppercase;

					&:hover {
						color: $primary;
					}
				}

				&:hover {
					ul {
						left: 0;
						// left: auto;
						// margin-left: -40px;
					}
				}
				&.current-menu-ancestor > a {
					color: $primary;
				}

				// Styliser un bouton CTA

				&.btn {
					padding: 0;
					background: none;
					a {
						display: block;
						color: #fff;
						font-size: 16px;
						line-height: 1.1;
						text-transform: none;
						border-radius: 5px;
						width: 180px;
						padding: 4px 6px;
						background: $grey-dark;
					}
				}
				&.rouge a {
					background: #d84927;
					&:hover {
						color:#fff;
					}
				}
			}
		}

		ul {
			// niveau 2
			position: absolute;
			left: -999em;
			top: 40px;
			padding-top: 5px;
			z-index: 99999;
			width: 1200px;
			columns: 4;
			// max-width: 330px;

			// display: flex;
			// flex-flow: row wrap;
			// justify-content: flex-start;

			padding: 12px 0 25px;
			background-color: #fff;
			box-shadow: 0 0 5px #ccc;

			> li {
				width: calc(1200px / 5);
				margin-bottom: 5px;
				text-align: left;

				&.current-menu-ancestor > a,
				&.current_page_ancestor > a {
					// border-bottom: 2px solid $primary;
				}

				li {
					&:hover > a,
					&.current-menu-item > a,
					&.current-menu-ancestor > a,
					&.current_page_ancestor > a {
						color: #fff;
						background-color: $primary;
					}
				}
			}

			a {
				display: block;
				padding: 5px 10px;
				font-family: "DINPro-cond", sans-serif;
				text-transform: initial;
			}

			&:hover > a,
			&.focus > a {
			}

			ul {
				position: static;
				display: flex;
				flex-flow: column wrap;
				background: none;
				box-shadow: none;
			}
		}
	}

	// ul ul > li + li {
	// 	border-left: 1px solid #b2aba7;
	// }

	.en-tete {
		& + .en-tete {
			border-left: 1px solid #b2aba7;
		}
		> a {
			color: #492d1e;
			text-transform: uppercase;
			border-bottom: 2px solid transparent;
			margin: 0 15px;
		}
	}

	// ul:first-child {

	//   display: flex;
	//   flex-flow: row wrap;
	//   justify-content: center;

	//   >li {

	//     margin: 0 20px;

	//     >a {
	//       font-family: 'DINPro-cond';
	//       font-size: 33px;
	//       display: block;
	//       color: $secondary;

	//       &:hover {
	//         color: $primary;
	//         ul {
	//           display: block;
	//         }
	//       }
	//     }

	//     &.current-menu-ancestor > a {
	//       color: $primary;
	//     }
	//   }

	// }

	li {
		display: inline-block;
		text-align: center;

		&:hover > a,
		&.focus > a {
			color: $grey-dark;
		}
	}

	a {
		// display: inline-block;
		font-family: "DINPro-cond", sans-serif;
		font-size: 18px;
		color: #836657;
		text-decoration: none;
		transition: 0.3s;
	}

	.current-menu-parent > a,
	.current_page_item > a,
	.current-menu-item > a,
	.current_page_ancestor > a,
	.current-menu-ancestor > a {
		color: $primary;
	}
}

.sous-nav {
	text-align: center;
	margin: 0;
	margin-bottom: 100px;
	padding: 0;

	li {
		list-style: none;
		display: inline-block;
		margin-right: 40px;
	}

	a {
		color: #000;
		font: normal 30px "DINPro-cond";
		text-decoration: none;

		&:hover,
		&.current {
			color: $primary;
		}
	}

	@include respond-to('tablet-only') {
		margin-bottom: 30px;
		border-bottom: 1px solid $grey-light;
		ul {
			display: flex;
			padding: 10px 0;
			margin: 0;
			overflow-x: scroll;
		}
		ul::-webkit-scrollbar {  
			display: none;
		}
		li {
			flex: 0 0 auto;
			// margin-right: 20px;
			text-align: left;
		}
		a {
			font-size: 1.5rem;
		}
	}
	// > p {
	// 	height: 0;
	// }
}

@media screen and (min-width: 768px) {
	// .main-navigation ul {
	//   display: flex;
	// }

	// li.hide-desktop {
	//   // class ajoutÃ©e dans l'admin
	//   display: none;
	// }
}

@media screen and (max-width: 1024px) {
	.menu-resp.open {
		display: block;
	}

	.main-navigation {
		display: none;
		position: fixed;
		top: 0;
		height: 100vh;
		width: 85vw;
		box-shadow: 0px 0px 12px #aaa;
		z-index: 999;
		padding-top: 50px;

		// la class arrive en jquery, le menu est ouvert
		&.open {
			display: block;
			background-color: #fff;
		}

		ul {
			display: block;
			overflow-y: auto;

			ul {
				width: auto;
				padding: 0;
				top: 0;
				margin: 10px 0;
				column-count: 1;

				li {
					margin: 0;
					padding: 5px;

					&:hover > ul,
					&.focus > ul {
						position: relative;
						left: 0;
						top: 0;
					}

					li a {
						padding-left: 25px;
					}
				}

				a {
					font-size: 18px;
					width: 100%;
					padding: 0 15px;
				}
			}

			li:hover > ul,
			li.focus > ul {
				position: relative;
			}
		}

		ul:first-child {
			> li {
				> a {
					// display: block;
					margin-top: 0;
					padding-left: 20px;

					&:before {
						position: relative;
						top: 5px;
						left: 0;
						// text-align: center;
						width: 20px;
						height: 25px;
						// display: inline-block;
					}

					// &:hover {
					// 	&:before {
					// 		background: none;
					// 	}
					// }
				}

				&.current-menu-ancestor {
				}

				&.btn {
					width: 75%;
					margin: 10px 0;
				}
			}
		}

		li {
			display: block;
			width: 100%;
			text-align: left;
			position: relative;
		}

		a {
			display: block;
			width: 100%;
			color: $grey-dark;
			font-size: 14px;
			font-family: "DINPro";
			margin: 0;

			&:hover {
				color: #fff !important;
				background-color: $primary;
			}
		}
	}
}

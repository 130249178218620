// ===============
// Masquer le lien du menu gÃ©nÃ©ral vers la celulle d'Ã©coute
// ===============

// JMJ
.page-id-17716,
// Denier
.page-id-18923,
// Donnons
.page-id-19486 {
    li.menu-item.btn.rouge {
        display: none;
    }
}
.simple-banner-text {

    .peledio {
        background: linear-gradient(70deg, #D46341 30px, #FA8057 70px, #8AC5DB 0px, #EDF6FB 70%, #EDF6FB);
        padding: 30px 0;

        @include respond-to('medium') {
            display: flex;
            justify-content: center;
            align-items: center;

            &>* {
                margin-right: 2rem;
            }
        }

        &>* {
            margin-bottom: 1rem;
        }
    }
}
/*!
Theme Name: Diocese
Description: ThÃ¨me gÃ©nÃ©ral
Version: 1.0.0
Text Domain: diocese
*/

@import "variables-site/variables-site";
@import "scss/fontawesome.scss";
@import "scss/solid.scss";
@import "mixins/mixins-master";

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
@import "normalize";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "typography/typography";

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
@import "elements/elements";

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
@import "forms/forms";
@import "forms/form-front";

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
@import "navigation/navigation";

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
@import "modules/accessibility";

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
@import "modules/alignments";

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
@import "modules/clearings";

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
@import "site/frontpage";
// @import "site/404";
@import "site/posts-and-pages";
@import "site/gutemberg-block";
@import "site/archives";
@import "site/actualites";
@import "site/carte";
@import "site/social";
@import "site/cta";
@import "site/sitemap";
@import "site/search";
@import "site/agenda";
@import "site/banner";
@import "site/liens-rapides";

/*--------------------------------------------------------------
## Posts Types
--------------------------------------------------------------*/
@import "post/personne";
@import "post/ensemble-paroissial";

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
// @import "site/comments";

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
@import "site/widgets";

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
@import "layout/content-sidebar";
// @import "layout/sidebar-content";
@import "layout/pre-header";
@import "layout/header";
@import "layout/footer";

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
// @import "modules/infinite-scroll";

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
@import "media/media";

@import "components/banner";
@import "navigation/custom";
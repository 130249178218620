.d-flex {
    display: flex !important;
}
.d-block {
    display: block !important;
}
.d-inline {
    display: inline !important;
}
.d-inline-block {
    display: inline-block !important;
}

.flex-row {
    flex-direction: row !important;
}
.flex-column {
    flex-direction: column !important;
}

.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-around {
    justify-content: space-around !important;
}


.align-items-start {
    align-items: flex-start !important;
}
.align-items-end {
    align-items: flex-end !important;
}
.align-items-center {
    align-items: center !important;
}
.align-items-baseline {
    align-items: baseline !important;
}
.align-items-stretch {
    align-items: stretch !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.w-100 {
    width: 100% !important;
}
.w-75 {
    width: 75% !important;
}
.w-50 {
    width: 50% !important;
}
.w-25 {
    width: 25% !important;
}

.bg-light {
    background-color: #fafafa !important;
}
.bg-dark {
    background-color: #424242 !important;
}
.bg-primary {
    background-color: $primary !important;
}
.bg-secondary {
    background-color: $secondary !important;
}

// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
	width: map-get($columns, $numberColumns) - (($columns__margin * ($numberColumns - 1)) / $numberColumns);
}

@mixin icon($content, $position: "before", $font-size: 16px, $padding: 7px) {
	@if $position==before {
		&:before {
			@extend %icon;
			content: $content;
			padding-right: $padding;
			font-size: $font-size;
		}
	}

	@if $position==after {
		&:after {
			@extend %icon;
			content: $content;
			padding-left: $padding;
			font-size: $font-size;
		}
	}
}

@mixin chevron($direction: "top") {

	// position: relative;
	&:before {
		position: absolute;
		top: 15px;
		right: 10px;
		content: "";
		border-style: solid;
		border-width: 0.15em 0.15em 0 0;
		display: inline-block;
		height: 0.45em;
		width: 0.45em;
		vertical-align: top;

		@if $direction==top {
			transform: rotate(-45deg);
		}

		@if $direction==right {
			transform: rotate(45deg);
		}

		@if $direction==bottom {
			transform: rotate(135deg);
		}

		@if $direction==left {
			transform: rotate(-135deg);
		}
	}
}

@mixin btn-color($bgcolor-default, $bgcolor-hover, $color, $color-hover) {
	color: $color  !important;
	background-color: $bgcolor-default;

	&:hover {
		color: $color-hover  !important;
		background-color: $bgcolor-hover;
	}
}

%btn {
	display: inline-block;
	padding: 10px 15px;
	border-radius: 30px;
	text-decoration: none;
	// text-transform: uppercase;
	color: #fff !important;
	background-color: $grey;
}

%btn-primary {
	@include btn-color($color__link, $color__link-hover, #fff, #fff);
}

%btn-secondary {
	@include btn-color($grey-dark, $grey, #fff, #fff);
}

%btn-danger {
	@include btn-color($color__alert, $color__alert-hover, #fff, #fff);
}

@mixin triangle($direction, $size: 6px, $color: #222, $ratio: 1) {
	content: "";
	display: block;
	position: absolute;
	height: 0;
	width: 0;

	@if ($direction =="up") {
		border-bottom: $size solid $color;
		border-left: $ratio * $size solid transparent;
		border-right: $ratio * $size solid transparent;
	}

	@else if ($direction =="down") {
		border-top: $size solid $color;
		border-left: $ratio * $size solid transparent;
		border-right: $ratio * $size solid transparent;
	}

	@else if ($direction =="left") {
		border-top: $ratio * $size solid transparent;
		border-bottom: $ratio * $size solid transparent;
		border-right: $size solid $color;
	}

	@else if ($direction =="right") {
		border-top: $ratio * $size solid transparent;
		border-bottom: $ratio * $size solid transparent;
		border-left: $size solid $color;
	}
}

// MEDIAQUERIES
// -------------------------------------------------------------------
@mixin respond-to($media) {
	@if $media==narrow {
		@media only screen and (min-width: 360px) {
			@content;
		}
	}

	@else if $media==medium {
		@media only screen and (min-width: 768px) {
			@content;
		}
	}

	@else if $media==wide {
		@media only screen and (min-width: 1024px) {
			@content;
		}
	}

	@else if $media==extra {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	}

	@else if $media==smartphone-only {
		@media only screen and (max-width: 360px) {
			@content;
		}
	}

	@else if $media==tablet-only {
		@media only screen and (max-width: 768px) {
			@content;
		}
	}
}

@mixin hideText {
	overflow: hidden;
	text-indent: 9999px;
	white-space: nowrap;
}
.site-pre-header {

    padding: 8px 25px 8px 50px; // plus a gauche pour le menu resp
    display: flex;
    justify-content: space-between;
    color: $grey-light;
    background: $grey-dark;

	// masquer sur mobile
    @include respond-to("tablet-only") {
        display: none;
    }

	// reset du padding left sur grand Ã©cran
    @include respond-to("extra") {
        padding-left: 25px; 
    }

    .infos-jour {
        display: flex;

		span {
			margin: 0 5px;
		}
        .nominis_lien {
            margin: 0;
        }
	}

    
	.separator {
		font-weight: bold;
	}

	.highlited {
		text-transform: uppercase;
		font-weight: bold;
		margin-right: 5px;
	}

	.lecture,
	.saint {
		font-weight: bold;
		@include respond-to("extra") {
			padding-left: 38px;
		}
	}

	.lecture,
	.nominis_lien {
		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	.lecture {
		@include respond-to("extra") {
			background: url("../img/lecture-blanc.png") no-repeat;
		}
	}

	.saint {
		@include respond-to("extra") {
			background: url("../img/saint-blanc.png") no-repeat;
		}
	}

    .social {

		ul {
			margin: 0;
		}

        li:last-child {
            margin-right: 0;
        }

		a {
			font-size: 20px;
			color: $grey-light;
			@include respond-to("wide") {
				font-size: 16px;
			}
		}
	}
} 
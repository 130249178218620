.don-en-ligne {
	@include respond-to("extra") {
		.container {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-around;
		}
		.col {
			@include column-width(3);
			@include respond-to("tablet-only") {
				margin-bottom: 20px;
			}
		}
	}
	.col-header {
		color: #fff;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		padding: 15px 0;
		background-color: $primary;
		border-radius: 3px;
		margin-bottom: 3px;
	}
	.col-body {
		padding: 25px;
		background-color: $grey-light;
		height: 100%;
	}
	.form-item + .form-item {
		margin-top: 17px;
	}
	label {
		display: block;
		font-weight: bold;
		margin-bottom: 4px;
	}
	.form-item-required label:after {
		content: "*";
		color: $color__alert;
	}
	button {
		margin: 0 auto;
		font-family: DINPro;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		color: #fff !important;
		width: 300px;
		height: 90px;
		border-radius: 3px;
		img {
			margin-top: 10px;
		}
	}
	.attachment-post-thumbnail {
		margin-bottom: 40px;
	}

	.form-item-checkbox {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		label {
			margin: 0 0 0 10px;
		}
	}
}

@font-face {
	font-family: "RoughBeautyScript";
	src: url("../fonts/RoughBeautyScript.woff2") format("woff2"), url("../fonts/RoughBeautyScript.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Trajan";
	src: url("../fonts/TrajanPro-Regular.otf");
}

@font-face {
	font-family: "DINPro";
	src: url("../fonts/DINPro.otf");
}
@font-face {
	font-family: "DINPro-bold";
	src: url("../fonts/DINPro-Bold.otf");
}

// @font-face{
//     font-family: "DINPro-Medium";
//     src: url('../../fonts/DINPro-Medium.otf');
// }
// @font-face{
//     font-family: "DINPro-condbold";
//     src: url('../../fonts/DINPro-CondBold.otf');
// }
@font-face {
	font-family: "DINPro-cond";
	src: url("../fonts/DINPro-CondMedium.otf");
}
// @font-face{
//     font-family: "DINPro-Black";
//     src: url('../../fonts/DINPro-Black.otf');
// }
// @font-face{
//     font-family: "DINPro-condbold";
//     src: url('../../fonts/DINPro-CondBold.otf');
// }
// @font-face{
//     font-family: "DINPro-condmedium";
//     src: url('../../fonts/DINPro-CondMedium.otf');
// }

$font__main: "DINPro";
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: normal;
$font__line-height-pre: 1.6;

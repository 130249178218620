.section-heading {
	text-align: center;
	text-transform: uppercase;
	font-family: "Trajan";
	font-weight: bold;
	padding: 35px 0;
	@include respond-to("medium") {
		padding: 65px 0;
	}
}

.section-heading-title {
	font-size: 24.5px;
	letter-spacing: 4.9px;
	color: $primary;
}

.section-heading-subtitle {
	font-size: 16.5px;
	color: $secondary;
}

.section-actualites {
	@include respond-to("wide") {
		.section-body {
			display: flex;
			flex: row wrap;
		}
	}

	.actu-list {
		@include respond-to("wide") {
			width: calc(100% / 3 - 3.33%);
			margin-right: calc(3.33%);
		}

		.widgettitle {
			position: relative;
			font-family: "DINPro-cond";
			color: #fff;
			font-size: 17px;
			background-color: $primary;
			padding: 5px;
			width: 113px;
			&:after {
				@include triangle("down", 14px, $primary);
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.actu-list .social {
		margin: 0;
		ul {
			margin: 10px 0 0;
		} 
	}

	.damier {
		display: flex;
		flex-flow: row wrap;
		@include respond-to("medium") {
			flex-direction: row;
		}
		@include respond-to("wide") {
			width: 66.667%;
			flex-direction: column;
		}

		a {
			text-align: center;
			color: #fff;
			font-family: "DINPro-cond";
			font-size: 30px;
			text-decoration: none;
			line-height: 1;
			padding: 10px;
			// height: 270px;
			height: 50vw;
			width: calc(100% / 2);
			@include respond-to("medium") {
				padding: 25px;
				width: calc(100% / 2);
			}
			@include respond-to("wide") {
				width: calc(100% / 3);
				height: 50%;
			}
		}

		.lien-1 {
			width: 100%;
			text-transform: uppercase;
			font-weight: bold;
			background: url("../img/actualites.jpg") center center no-repeat;
			background-size: cover;
			@include respond-to("wide") {
				height: 100%;
				width: calc(100% / 3);
			}
		}

		.lien-2 {
			display: flex;
			flex-flow: column wrap;
			justify-content: space-around;
			align-items: center;
			color: #fff;
			background-color: $primary;
		}

		.lien-3 {
			text-transform: uppercase;
			font-weight: bold;
			background: url("../img/agenda.jpg") center center no-repeat;
			background-size: cover;
		}

		.lien-4 {
			text-transform: uppercase;
			font-weight: bold;
			background: url("../img/videos.jpg") center center no-repeat;
			background-size: cover;
		}

		.lien-5 {
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			span {
				padding-top: 20px;
			}
			background: url("../img/photos.jpg") center center no-repeat;
			background-size: cover;
		}
	}
}

.section-dossiers {
	// @include respond-to("medium") {
	.damier {
		display: flex;
		flex-flow: row wrap;
	}
	// }

	.dossier,
	.dossier-intro {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 400px;
		text-align: center;
		font-size: 40px;
		// text-decoration: none;
		font-family: "DINPro-cond";
		text-shadow: 1px 1.7px 3px #00000091;
		font-family: DINPro;
		color: #fff;

		width: calc(100% / 2);
		height: 50vw;
		font-size: 30px;
		padding: 10px;
		overflow: hidden;
		@include respond-to("medium") {
			line-height: 62.5px;
			padding: 40px;
			width: calc(100% / 2);
			height: 400px;
			font-size: 75px;
			padding: 110px;
		}
	}

	.dossier-illustration {
		position: absolute;
		max-width: none;
		@include respond-to("medium") {
			width: 600px;
			height: 400px;
		}
	}

	.dossier-titre {
		position: absolute;
	}

	.dossier-intro {
		align-self: flex-start;
		color: $primary;
		font-family: "DINPro-cond";
		font-weight: bold;
		font-size: 30px;
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		text-shadow: none;
		background: url("../img/picto-dossiers.png") 50% 80% no-repeat, url("../img/bg-dossier.png") 50% 50% no-repeat;
		background-size: 30px 30px, 100% 100%;
		background-color: #ccc;
		padding-top: 0;
		@include respond-to("medium") {
			font-size: 60px;
			background: url("../img/picto-dossiers.png") 50% 80% no-repeat,
				url("../img/bg-dossier.png") 50% 50% no-repeat;
			font-size: 62.5px;
		}
	}
}

.section-paroisses {
	@include respond-to("wide") {
		.section-body {
			display: flex;
			flex-flow: row wrap;
		}

		.carte,
		.infos {
			height: 430px;
			width: 50%;
		}
	}

	.carte {
		height: 430px;
		display: flex;
		background: url("../img/carte.jpg") 50% 50% no-repeat;
		background-size: cover;

		a {
			color: #fff !important;
			text-transform: uppercase;
			font-family: DINPro;
			font-size: 12.5px;
			font-weight: bold;
			padding: 18px 23px;
			margin: auto;
		}
	}

	.infos {
		padding: 50px;
		@include respond-to("medium") {
			padding: 150px 105px;
		}

		.text {
			img {
				float: left;
				margin-right: 22px;
			}

			span {
				font-family: "DINPro";
				font-size: 22.9px;
				text-align: left;
				color: #fff;
				line-height: 1.2;
			}
		}

		form {
			// position: relative;
			padding: 20px 0;
			display: flex;
			justify-content: space-between;
		}

		input {
			color: $primary;
			padding: 10px 12px;
		}

		input::placeholder {
			color: $primary;
		}

		.search-submit {
			// position: absolute;
			// top: 20px;
			// right: 5px;
			// color: $primary !important;
			// background: none;
			color: #fff !important;
			background-color: $secondary;
		}

		background-color: $primary;
	}
}

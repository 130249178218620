@import "no-sidebar";

.site-content {
	margin: 70px 10px 40px;
	position: relative;
}

.content-header {
	text-align: center;
	// padding: 40px 0;
}

.widget-area {
	margin-top: 40px;
}

@media screen and (min-width: 1024px) {
	.site {
		width: 100%;
		margin: 0 auto;
	}

	.site-content {
		margin: 0 0 40px;
	}

	// .content-body {
	// display: flex;
	// align-items: stretch;
	// justify-content: space-between;
	// }

	// .main-area {
	// flex: 7;
	// }

	// .widget-area {
	// flex: 3;
	// margin-top: 0;
	// margin-left: 10px;
	// }

	// .site-footer {
	// 	clear: both;
	// 	width: 100%;
	// }
}

@media screen and (min-width: 1200px) {
	.site,
	.site-footer {
		width: 1200px;
		margin: 0 auto;
	}
}

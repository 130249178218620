//
// VUE GENERALE
//

// Couleur btn recherche
.tribe-common .tribe-common-c-btn,
.tribe-common a.tribe-common-c-btn {
  background: none;
  border: 1px solid #d5d5d5;
  border-radius: 4px;

  &:hover {
    border: none;
  }
}

// Couleur trait sous le mois sÃ©lectionnÃ©
.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-view-selector--tabs .tribe-events-c-view-selector__list-item--active .tribe-events-c-view-selector__list-item-link::after {
  background: $primary;
}

// Zone mois et liste
.tribe-common--breakpoint-medium.tribe-events .tribe-events-l-container {
  padding-top: 20px;
  padding-top: 60px;
}


// Taille colonne vue liste
.tribe-common--breakpoint-medium.tribe-events .tribe-events-calendar-list__event-featured-image-wrapper {
  width: 25%;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-calendar-list__event-details {
  width: 75%;
}

//
// SINGLE
//

.single-tribe_events .type-tribe_events {

  @include respond-to('medium') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    // Image Ã  la une
    .tribe-events-event-image {
      flex: 1;
      margin: 0 30px 50px 0;
    }

    .tribe-events-content {
      flex: 2;
    }

    .tribe-events-meta-group-gmap {
      width: 500px;

      .tribe-events-venue-map {
        width: 500px;
      }
    }
  }

  // Liens ajout au calendrier
  .tribe-events-cal-links {
    a {
      @extend %btn;
      padding-left: 10px;
      margin: 5px;
    }
  }



}

.tribe-events-single-event-title {
  @extend h1;
}

// Masquer les tags, utiliser seulement pour faire remonter des events par shortcodes
.tribe-event-tags-label,
.tribe-event-tags {
  display: none;
}


// ===============
// COUNT DOWN dans la banner
// ===============

// Masquet le titre de l'Ã©venement pour pas faire lien dessus
.tribe-events-widget-countdown__event-title {
  display: none;
}
.content-actualites {
  ul {
    list-style: none;
  }

  h2 {
    margin-bottom: 5px;
  }

  p {
    margin: 0;

    &.excerpt {
      margin-bottom: 5px;
    }
  }

  h2 {
    font-size: 2rem;

    @include respond-to('tablet-only') {
      font-size: 1.5rem;
    }
  }

  .post {
    margin: 0;
  }

  //   .list-categories {
  //     text-align: center;

  //     a {
  //       margin: 0 10px;
  //     }
  //   }

  .categories {
    padding: 10px 0;
  }

  .category {
    font-size: 12px;
    border-radius: 5px;
    padding: 2px 10px;
    background: #ccc;
  }

  .category+.category {
    margin-left: 10px;
  }
}

.read-more {
  font-weight: bold;
  text-decoration: none;
}

.site-content {
  .social {
    text-align: center;
    margin: 15px 0;
    @include respond-to('medium') {
      margin: 35px 0;
    }
  }

  .share {
    display: inline-block;
    color: #fff;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    background-color: $grey-dark;
  }
}


@include respond-to('medium') {
  .actualites-body {
    display: flex;
  }

  .actualites-content {
    flex: 2;
  }

  .actualites-sidebar {
    flex: 1;
    margin-left: 30px;
  }
}

// Autres articles
.autres-articles {
  background: lighten($primary, 50%);

  div {
    padding: 30px 15px;
  }

  @include respond-to('medium') {
    display: flex;
    justify-content: space-between;

    div {
      width: 50%;
      padding: 30px;
    }
  }

  div+div {
    border-top: 5px dotted $grey-light;

    @include respond-to('medium') {
      border-top: none;
      border-left: 5px dotted $grey-light;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-top: 0;
  }

  li {
    list-style: none;
    margin-bottom: 5px;
  }

  a:hover {
    text-decoration: underline;
  }
}
